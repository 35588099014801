<template>
  <div class="content">
    <ApplicationNannyInputResponsibilities
      v-model:child-care="applicationNanny.responsibilities.careChild"
      v-model:activities="applicationNanny.responsibilities.activities"
      v-model:home-care="applicationNanny.responsibilities.careHome"
      @update:is-valid="isValid = $event"
    />

    <ButtonPrimaryNext
      :to="{ name: 'ApplicationNannyCreatePerks' }"
      :isDisabled="!isValid"
    />
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useApplicationNannyStore } from "@/stores/applicationNanny";

import ApplicationNannyInputResponsibilities from "@/components/Application/Nanny/ApplicationNannyInputResponsibilities.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";

export default {
  components: {
    ApplicationNannyInputResponsibilities,
    ButtonPrimaryNext,
  },

  setup() {
    const applicationNanny = useApplicationNannyStore();

    const isValid = ref(false);

    return {
      applicationNanny,
      isValid,
    };
  },
};
</script>

<style scoped>
.content {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
@media (max-width: 1200px) {
}
</style>
