<template>
  <div class="container">
    <BaseH1 :html="'Zaznacz,<br />co Ci odpowiada'" />

    <div class="wrapper-checkboxes">
      <BaseH2 :text="'Opieka nad dzieckiem'" />
      <InputCheckboxGroupChipsChildCare
        v-model="application.responsibilities.childCare"
      />
    </div>

    <div class="wrapper-checkboxes">
      <BaseH2 :text="'Zajęcia'" />
      <InputCheckboxGroupChipsActivities
        v-model="application.responsibilities.activities"
      />
    </div>

    <div class="wrapper-checkboxes">
      <BaseH2 :text="'Pomoc domowa'" />
      <InputCheckboxGroupRound
        v-model="application.responsibilities.homeCare"
        :name="'home-care'"
        :options="optionsHomeCare"
      />
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import useApplicationNannyDictionary from "@/composables/useApplicationNannyDictionary";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import InputCheckboxGroupChipsChildCare from "@/components/UI/InputCheckboxGroupChipsChildCare.vue";
import InputCheckboxGroupChipsActivities from "@/components/UI/InputCheckboxGroupChipsActivities.vue";
import InputCheckboxGroupRound from "@/components/UI/Base/InputCheckboxGroupRound.vue";

export default {
  components: {
    BaseH1,
    BaseH2,
    InputCheckboxGroupChipsChildCare,
    InputCheckboxGroupChipsActivities,
    InputCheckboxGroupRound,
  },

  props: {
    childCare: Array,
    activities: Array,
    homeCare: Array,
  },

  emits: [
    "update:childCare",
    "update:activities",
    "update:homeCare",
    "update:isValid",
  ],

  setup(props, { emit }) {
    const application = reactive({
      responsibilities: {
        childCare: props.childCare,
        activities: props.activities,
        homeCare: props.homeCare,
      },
    });

    // Way out binding for responsibilities
    watch(
      () => application.responsibilities.childCare,
      (newChildCare) => emit("update:childCare", newChildCare)
    );
    watch(
      () => application.responsibilities.activities,
      (newActivities) => emit("update:activities", newActivities)
    );
    watch(
      () => application.responsibilities.homeCare,
      (newHomeCare) => emit("update:homeCare", newHomeCare)
    );

    // Way in binding for responsibilities
    watch(
      () => props.childCare,
      (newChildCare) => (application.responsibilities.childCare = newChildCare)
    );
    watch(
      () => props.activities,
      (newActivities) =>
        (application.responsibilities.activities = newActivities)
    );
    watch(
      () => props.homeCare,
      (newHomeCare) => (application.responsibilities.homeCare = newHomeCare)
    );

    const isValid = computed(() => {
      return !!application.responsibilities.childCare.length;
    });

    // Way out binding for isValid
    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    const { DICTIONARY } = useApplicationNannyDictionary();

    const optionsHomeCare = Object.values(DICTIONARY.responsibilities.homeCare);

    return {
      application,
      isValid,
      optionsHomeCare,
    };
  },
};
</script>

<style scoped>
.container {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 80px;
}
.wrapper-checkboxes {
  width: 560px;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 15px;
}

@media (max-width: 1200px) {
  .container {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 60px;
  }
  .wrapper-checkboxes {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    row-gap: 15px;
  }
}
</style>
